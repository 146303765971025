const sanityClient = require("@sanity/client");
const imageUrlBuilder = require("@sanity/image-url");
// Sanity client
const client = sanityClient({
  projectId: process.env.GATSBY__SANITY_PROJECT_ID || "imclblh9",
  dataset: process.env.GATSBY__SANITY_DATASET || "production",
  apiVersion: "2021-03-25",
  useCdn: false,
});
// Image builder
const builder = imageUrlBuilder(client);
const generateImageUrl = (source) => {
  return builder.image(source);
};
// Quries
const pageQuery =
  '*[_type == "pages"] {_id,title, slug,theme,top_banner,content,seo, socialSharing , header_options}';

const getPages = async () => {
  return await client.fetch(pageQuery).then((page) => {
    return page;
  });
};

const blogQuery =
  '*[_type == "posts"] {_id,title, slug,top_banner,hero_image,date,author, excerpt,content,relatedPosts,shortDesc,seo,socialSharing,featuredBlog,highlightedBlog,hideBlog,hide_hero,hide_share_button,hide_next_up,showAssessmentTool}';

const getBlogs = async () => {
  return await client.fetch(blogQuery).then((blogs) => {
    return blogs;
  });
};

const mediaQuery =
  '*[_type == "mediaFiles"] {_id,title,"bgImage":bgImage.asset->,description,date,type,"file":file.asset->url,categoryType,externalLink,content,socialSharing}';

const getMedia = async () => {
  return await client.fetch(mediaQuery).then((media) => {
    return media;
  });
};

const settingsQuery = '*[_type == "siteSettings"] {_id,footerLinks}';

const getSettings = async () => {
  return await client.fetch(settingsQuery).then((settings) => {
    return settings;
  });
};

// Functions
const getImageSize = (fileName) => {
  const sizeRegex = new RegExp(/(\d+)x(\d+)/);
  const size = sizeRegex.exec(fileName);

  return {
    width: size[1],
    height: size[2],
  };
};

module.exports = {
  // serializeContentEditor,
  getPages,
  // serialize,
  generateImageUrl,
  getImageSize,
  getBlogs,
  getMedia,
  getSettings,
};
