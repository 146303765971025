import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import HeroSection from "../components/pageComponents/blogDetailHeroSection";
import Spacer from "../components/spacer";
import BlogContent from "../components/pageComponents/blogContent";
import NewsLetter from "../components/pageComponents/blogDetailNewsLetter";
import UpToDateForm from "../components/forms/upToDateForm";
import DownloadChapter from "../components/pageComponents/blogDetailDownloadChapter";
import AttributeIncorporated from "../components/pageComponents/blogDetailAttributeIncorporated";
import AuthorOfAttribute from "../components/pageComponents/blogDetailAutor";
import NextUp from "../components/pageComponents/blogDetailNextUp";
import Discover from "../components/pageComponents/blogDetalDiscoverAttribute";
import VideoSection from "../components/videoSection";
import StaticAssessmentTool from "../components/pageComponents/staticAssesmentTool";
import BlockContent from "@sanity/block-content-to-react";
import * as typeformEmbed from "@typeform/embed";
import { generateImageUrl, getImageSize } from "../sanity";
import { Link } from "gatsby";
const BlogDetail = ({
  pageContext: { blog, allBlogs, settings },
  location,
}) => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    let _blogs = [];
    if (blog?.relatedPosts && blog?.relatedPosts?.length) {
      blog?.relatedPosts?.forEach((item) => {
        allBlogs?.forEach((all) => {
          if (item?._ref === all._id) {
            _blogs.push(all);
          }
        });
      });
      setBlogs(_blogs);
    }
  }, [allBlogs, blog?.relatedPosts]);

  return (
    <>
      <Layout
        settings={settings}
        top_banner={blog?.top_banner}
        location={location}
        pageSlug={"blog/" + blog?.slug?.current}
        pageTitle={blog?.title || ""}
        social={blog?.socialSharing}
        seo={blog?.seo}
      >
        <Spacer
          section={{
            size: "s",
          }}
        />
        <HeroSection blog={blog} />
        <div className="container mx-auto py-20 blog_detail_content">
          {blog.content && blog.content.content && (
            <div className="content-builder-blog w-full">
              <BlockContent
                blocks={blog.content?.content}
                serializers={serializeContentEditor(
                  "w-full px-6 lg:w-3/5 mx-auto lg:px-0"
                )}
              />
            </div>
          )}
          {!blog?.hide_next_up && (
            <>
              <Spacer section={{ size: "s" }} />
              <NextUp />
              <Spacer section={{ size: "s" }} />
              {blogs &&
                blogs.map((item, index) => {
                  return (
                    <div key={index}>
                      <BlogContent
                        blog={item}
                        serializeContentEditor={serializeContentEditor}
                      />
                      <Spacer section={{ size: "s" }} />
                    </div>
                  );
                })}
            </>
          )}
        </div>
        {blog?.showAssessmentTool && <StaticAssessmentTool />}
      </Layout>
    </>
  );
};
export default BlogDetail;
const linkReturnHandler = (link, node) => {
  if (link?.indexOf("http") !== -1) {
    return (
      <a
        className="text-primary-red hover:text-black cursor-pointer"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {node?.children}
      </a>
    );
  } else {
    return (
      <Link
        className="text-primary-red hover:text-black cursor-pointer"
        to={link}
      >
        {node?.children}
      </Link>
    );
  }
};
const serializeContentEditor = (classes, isCustom) => {
  const serializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "normal":
            return (
              <p
                className={`${classes} ${
                  !isCustom ? "text-2xl pb-5 pr-6 lg:pr-16" : ""
                }`}
              >
                {props?.children?.map((child, childIndex) => {
                  if (child?.props?.node?.mark?._type === "link") {
                    let node = child?.props?.node;
                    let link = node?.mark?.href;
                    return linkReturnHandler(link, node);
                  } else if (child?.props?.node?.mark === "strong") {
                    return child?.props?.node?.children?.map((schild) => {
                      if (schild?.props?.node?.mark?._type === "link") {
                        let _node = schild?.props?.node;
                        let _link = _node?.mark?.href;
                        return <b>{linkReturnHandler(_link, _node)}</b>;
                      } else {
                        return <b>{schild}</b>;
                      }
                    });
                  } else if (child?.props?.node?.mark === "em") {
                    return child?.props?.node?.children?.map((schild) => {
                      if (schild?.props?.node?.mark?._type === "link") {
                        let _node = schild?.props?.node;
                        let _link = _node?.mark?.href;
                        return <em>{linkReturnHandler(_link, _node)}</em>;
                      } else {
                        return <em>{schild}</em>;
                      }
                    });
                  } else {
                    return child;
                  }
                })}
              </p>
            );
          case "headline":
            return (
              <h3
                className={`${classes} headline pb-16 font-bold text-3xl lg:text-4xl lg:leading-42`}
              >
                {props.children}
              </h3>
            );
          case "h1":
            return (
              <h1
                className={`${classes} text-5xl lg:text-100 font-black leading-54 lg:leading-110 font-Brother lg:font-Dazzed md:px-5 lg:px-0  pb-6`}
              >
                {props.children}
              </h1>
            );
          case "smaller":
            return (
              <p className="w-full lg:w-3/5 mx-auto text-xl leading-32 pb-6">
                {props.children}
              </p>
            );
          default:
            return (
              <p className="text-2xl leading-32 w-full px-6 lg:px-0 lg:w-3/5 mx-auto pb-6">
                {props.children}
              </p>
            );
        }
      },
      contentEditor: () => {},
      contentEditorMinimal: (props) => {},
      customImage: (props) => {
        const { width, height } = getImageSize(props.node.image.asset._ref);
        const multiplier = props.node.is_retina ? 0.5 : 1;
        let attrs = {
          width: width * multiplier,
          height: height * multiplier,
          src: generateImageUrl(props.node.image.asset._ref),
          loading: "lazy",
          classes: props.node.cssClasses,
        };
        return (
          <img
            {...attrs}
            className={classes + "mx-auto py-12"}
            alt={props.node.alt}
            loading="lazy"
          />
        );
      },
      buttonLink: (props) => {
        const { url, text, new_window } = props.node;
        let classes =
          "bg-primary-red hover:bg-black text-white transition duration-300 ease-in-out px-7 py-4 rounded-full cursor-pointer";
        if (url.indexOf("http") !== -1) {
          if (new_window) {
            return (
              <a
                className={classes}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </a>
            );
          } else {
            return (
              <a className={classes} href={url}>
                {text}
              </a>
            );
          }
        } else {
          return (
            <Link to={url}>
              <button className={classes}>{text}</button>
            </Link>
          );
        }
      },
      typeformButton: (props) => {
        const { text, id } = props.node;
        return (
          <button
            onClick={() => typeFormClickHandler(id)}
            className={`${classes}`}
          >
            {text}
          </button>
        );
      },
      contentEditorSpacer: (props) => {
        const { height } = props.node;
        return (
          <span
            style={{ display: "block", height: height + "px" }}
            aria-hidden="true"
          />
        );
      },
      signUpBlockHeading: (props) => {
        return (
          <NewsLetter
            title={props?.node?.heading}
            event="newsletter_signup_article_page"
          />
        );
      },
      signUpBlockHeadingDescription: (props) => {
        return (
          <UpToDateForm
            noRounded
            section={props?.node}
            event="newsletter_signup_article_page"
          />
        );
      },
      downloadBlockWithBookImage: (props) => {
        return (
          <DownloadChapter
            section={props?.node}
            event="first_chapter_signup_article_page"
          />
        );
      },
      downloadBlockWithCompanyImage: (props) => {
        return <AttributeIncorporated section={props?.node} />;
      },
      authorBlock: (props) => {
        return <AuthorOfAttribute section={props?.node} />;
      },
      discoverBlock: (props) => {
        return <Discover section={props?.node} />;
      },
      youtube: (props) => {
        return <VideoSection section={props?.node} />;
      },
      marks: {
        highlight: (props) => {
          return (
            <span className="text-3xl lg:text-4xl lg:leading-42 text-white lg:text-light-yellow font-bold font-Dazzed">
              {props.children}
            </span>
          );
        },
      },
      list: (props) => {
        return <ul>{props?.children}</ul>;
      },
      listItem: (props) => {
        return (
          <li className="content-list relative text-2xl leading-32 mb-3">
            <svg
              className="icon-arrow-right absolute"
              style={{
                height: "8px",
                left: "-30px",
                width: "28px",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="#B4A993"
              viewBox="0 0 28 8"
              aria-hidden="true"
            >
              <path d="M27.3536 4.35355C27.5488 4.15829 27.5488 3.84171 27.3536 3.64644L24.1716 0.464464C23.9763 0.269202 23.6597 0.269202 23.4645 0.464464C23.2692 0.659726 23.2692 0.976309 23.4645 1.17157L26.2929 4L23.4645 6.82843C23.2692 7.02369 23.2692 7.34027 23.4645 7.53553C23.6597 7.73079 23.9763 7.73079 24.1716 7.53553L27.3536 4.35355ZM4.37114e-08 4.5L27 4.5L27 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"></path>
            </svg>
            {props.children}
          </li>
        );
      },
    },
  };

  return serializers;
};
const typeFormClickHandler = (typeformId) => {
  const popup = typeformEmbed.makePopup(
    `https://form.typeform.com/to/${typeformId}`,
    {
      mode: "popup",
      hideHeaders: true,
      hideFooters: true,
      autoOpen: false,
    }
  );
  popup.open();
};
