import React from "react";
import Layout from "../components/layout";
import HomePageHero from "../components/pageComponents/homePageHero";
import VideoSection from "../components/videoSection";
import ContentEditor from "../components/pageComponents/content";
import HomePageBookSection from "../components/pageComponents/homePageBookSection";
import HomepageReview from "../components/pageComponents/ratings/ratingTempleteOne";
import HomePageServices from "../components/pageComponents/homePageServices";
import AttributeBlog from "../components/pageComponents/homePageAttributrBlog";
import SignupBlock from "../components/forms/upToDateForm";
import AssesmentTool from "../components/pageComponents/assesmentTool";
import Spacer from "../components/spacer";
import AuthorPageHero from "../components/pageComponents/aboutHeroSection";
import LogosRow from "../components/pageComponents/logos";
import BoxedContent from "../components/pageComponents/aboutPageKeynote";
import AuthorPageContent from "../components/pageComponents/aboutPageContent";
import AuthorPageReviews from "../components/pageComponents/ratings/ratingTempleteFour";
import EventsBlock from "../components/pageComponents/aboutPageTable";
import { generateImageUrl, getImageSize } from "../sanity";
import AboutForm from "../components/pageComponents/aboutPageForm";
import BuyPageHero from "../components/pageComponents/buyTheBookHeroSection";
import BuyPageContent from "../components/pageComponents/buyTheBookSucceedSection";
import BlogpPageHero from "../components/pageComponents/blogPageHeroSection";
import ConsultingHero from "../components/pageComponents/consultingHeroSection";
import WorkbooksHero from "../components/pageComponents/workbooksHeroSection";
import WorkBooksPricing from "../components/pageComponents/workbookPricing";
import WorkbookCover from "../components/pageComponents/workbookCover";
import AssesmentHero from "../components/pageComponents/assessmentHero";
import AssessmentHandleStress from "../components/pageComponents/assessmentHandleStress";
import AssessmentMentalAcuity from "../components/pageComponents/assessmentMentalAcuity";
import AssesmentTake from "../components/pageComponents/assessmentTake";
import HTMLCode from "../components/pageComponents/HTMLcode";
import Media from "../components/pageComponents/media";
import * as typeformEmbed from "@typeform/embed";
import NewsLetterHero from "../components/pageComponents/newsletterHeroSection";
import SocialLinks from "../components/pageComponents/socialLinks";
import TypeFormLoader from "../components/pageComponents/typeformLoader";
import PressHeader from "../components/pageComponents/pressAndMedia";
import WritingList from "../components/pageComponents/writingList";
import BrandAssets from "../components/pageComponents/brandAssetsBody";
import AllAttributeHeader from "../components/pageComponents/allAttributesHeroSection";
import AttributeList from "../components/pageComponents/allAttributeList";
import TrustPageHero from "../components/pageComponents/trustPageHero";
import TrustPageDownloadPdf from "../components/pageComponents/trustDownloadPdf";
import TrustPageServices from "../components/pageComponents/trustPageServices";
import TrustPageCard from "../components/pageComponents/trustPageCards";
import TrustExerciseHero from "../components/pageComponents/trustExerciseHero";
import TrustExerciseFeaturedLogos from "../components/pageComponents/trustExerciseFeatured";
import TrustExerciseReasonContent from "../components/pageComponents/trustExerciseReasonContent";
import TrustExerciseVideoSection from "../components/pageComponents/trustExerciseVideo";
import TrustExerciseUnderstanding from "../components/pageComponents/trustExerciseUnderstanding";
import TrustExerciseImage from "../components/pageComponents/trustExerciseImage";
import TrustExerciseElements from "../components/pageComponents/trustExerciseElements";
import TrustExerciseSingleImage from "../components/pageComponents/trustExerciseSingleImage";
import TrustDownloadFall from "../components/pageComponents/trustDownloadFall";
import MeetingForm from "../components/pageComponents/meetingForm";
import ServicesHero from "../components/pageComponents/servicesHero";
import ServicesCuttingEdge from "../components/pageComponents/servicesCuttingEdge";
import ServicesToolsPerform from "../components/pageComponents/servicesToolsPerform";
import ServicesOffer from "../components/pageComponents/servicesOffer";
import ServicesDiscover from "../components/pageComponents/servicesDiscover";
import Serviceslogos from "../components/pageComponents/servicesLogos";
import CourseCollectiveAndIndividual from "../components/pageComponents/courseCollectionAndIndividual";
import CourseTraining from "../components/pageComponents/courseTraining";
import CourseHero from "../components/pageComponents/courseHero";
import CourseDetailHero from "../components/pageComponents/courseDetailHero";
import CourseDetailPerformanceList from "../components/pageComponents/courseDetailPerformanceList";
import CourseDetailpricing from "../components/pageComponents/detailCoursePricing";
import WhatsYourGoal from "../components/pageComponents/whatsYourGoal";
import ThreePointFeatureBlock from "../components/pageComponents/threePointFeatureBlock";
import TwoColumnElement from "../components/pageComponents/twoColumnElement";
import Testimonial from "../components/pageComponents/testimonial";
import Faq from "../components/pageComponents/faq";
import BlindSpotAndStrength from "../components/pageComponents/blindspotAndStrength";
import LiveTime from "../components/pageComponents/liveTime";
const IndexPage = ({
  pageContext: { page, blogs, mediaFiles, settings },
  location,
}) => {
  const renderSections = (section, index) => {
    switch (section._type) {
      case "homepageHero":
        return (
          <HomePageHero key={index} section={section} serialize={serialize} />
        );
      case "spacer":
        return <Spacer key={index} section={section} />;
      case "youtube":
        return <VideoSection key={index} section={section} />;
      case "homepageReviews":
        return <HomepageReview key={index} section={section} />;
      case "contentEditor":
        return (
          <ContentEditor
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "homePageBook":
        return <HomePageBookSection key={index} section={section} />;
      case "homepageServices":
        return (
          <HomePageServices
            key={index}
            section={section}
            serialize={serialize}
          />
        );
      case "homeBlog":
        return (
          <AttributeBlog
            key={index}
            section={section}
            allBlogs={blogs}
            serialize={serialize}
          />
        );
      case "homepageAssessment":
        return (
          <AssesmentTool
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "signupBlock":
        return (
          <div key={index} className="px-5 lg:px-0">
            <SignupBlock section={section} />
          </div>
        );
      case "authorPageHero":
        return (
          <AuthorPageHero
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "logosRow":
        return <LogosRow key={index} section={section} />;
      case "boxedContent":
        return (
          <BoxedContent
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "authorContentEditor":
        return (
          <AuthorPageContent
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "authorPageReviews":
        return <AuthorPageReviews key={index} section={section} />;
      case "eventsBlock":
        return <EventsBlock key={index} section={section} />;
      case "authorPageForm":
        return <AboutForm key={index} section={section} />;
      case "buyPageHero":
        return (
          <BuyPageHero
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "buyPageContent":
        return (
          <BuyPageContent
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "blogPageHero":
        return (
          <BlogpPageHero
            key={index}
            section={section}
            blogs={blogs}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "consultingPagehero":
        return <ConsultingHero key={index} section={section} />;
      case "workBooksPageHero":
        return (
          <WorkbooksHero
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "workBooksPricing":
        return <WorkBooksPricing key={index} section={section} />;
      case "workbookPageCovers":
        return <WorkbookCover key={index} section={section} />;
      case "assessmentPageHero":
        return <AssesmentHero key={index} section={section} />;
      case "assessmentPageContent":
        return (
          <AssessmentHandleStress
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "assessmentPageFeatures":
        return <AssessmentMentalAcuity key={index} section={section} />;
      case "assesmentBoxedContent":
        return (
          <AssesmentTake
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "htmlCode":
        return <HTMLCode key={index} section={section} />;
      case "media":
        return <Media key={index} section={section} mediaFiles={mediaFiles} />;
      case "newsletterHero":
        return (
          <NewsLetterHero key={index} section={section} serialize={serialize} />
        );
      case "socialLinksComponent":
        return <SocialLinks key={index} section={section} />;
      case "typeformIframeEmbed":
        return (
          <TypeFormLoader key={index} section={section} location={location} />
        );
      case "pressHeader":
        return <PressHeader key={index} section={section} />;
      case "writingContent":
        return <WritingList key={index} section={section} />;
      case "mediaAssets":
        return <BrandAssets key={index} section={section} />;
      case "allAttributesHeader":
        return <AllAttributeHeader key={index} section={section} />;
      case "arrtibutesList":
        return <AttributeList key={index} section={section} />;
      case "trustPageHero":
        return <TrustPageHero key={index} section={section} />;
      case "trustPageDownloadPdf":
        return <TrustPageDownloadPdf key={index} section={section} />;
      case "trustPageServices":
        return (
          <TrustPageServices
            key={index}
            section={section}
            serialize={serialize}
          />
        );
      case "trustPageCards":
        return (
          <TrustPageCard key={index} section={section} serialize={serialize} />
        );
      case "trustExercisePageHero":
        return <TrustExerciseHero key={index} section={section} />;
      case "trustExerciseFeatured":
        return <TrustExerciseFeaturedLogos key={index} section={section} />;
      case "trustExerciseReasonContent":
        return (
          <TrustExerciseReasonContent
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "trustExerciseVideo":
        return <TrustExerciseVideoSection key={index} section={section} />;
      case "trustExerciseUnderstanding":
        return (
          <TrustExerciseUnderstanding
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "trustExerciseImage":
        return <TrustExerciseImage key={index} section={section} />;
      case "trustExerciseElements":
        return (
          <TrustExerciseElements
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "trustExerciseSingleImage":
        return <TrustExerciseSingleImage key={index} section={section} />;
      case "downloadTrustFall":
        return <TrustDownloadFall key={index} section={section} />;
      case "meetingForm":
        return <MeetingForm key={index} section={section} />;
      case "servicesHero":
        return (
          <ServicesHero
            key={index}
            section={section}
            header_options={page?.header_options}
          />
        );
      case "servicesCuttingEdge":
        return <ServicesCuttingEdge key={index} section={section} />;
      case "servicesToolPerform":
        return <ServicesToolsPerform key={index} section={section} />;
      case "servicesOffer":
        return <ServicesOffer key={index} section={section} />;
      case "servicesDiscover":
        return <ServicesDiscover key={index} section={section} />;
      case "servicesLogos":
        return <Serviceslogos key={index} section={section} />;
      case "courceCollectionAndIndividuals":
        return <CourseCollectiveAndIndividual key={index} section={section} />;
      case "courseTraining":
        return <CourseTraining key={index} section={section} />;
      case "courseHero":
        return (
          <CourseHero
            key={index}
            section={section}
            header_options={page?.header_options}
          />
        );
      case "contentEditorNew":
        return (
          <CourseDetailHero
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "courceDetailPerformanceList":
        return <CourseDetailPerformanceList key={index} section={section} />;
      case "courseDetailPricing":
        return <CourseDetailpricing key={index} section={section} />;
      case "whatsYourGoal":
        return <WhatsYourGoal key={index} section={section} />;
      case "threePointsFeatureBlock":
        return <ThreePointFeatureBlock key={index} section={section} />;
      case "headingAndTwoColumnContentEditor":
        return (
          <TwoColumnElement
            key={index}
            section={section}
            serializeContentEditor={serializeContentEditor}
          />
        );
      case "testimonial":
        return <Testimonial key={index} section={section} />;
      case "faq":
        return <Faq key={index} section={section} />;
      case "blindspotsAndStrength":
        return <BlindSpotAndStrength key={index} section={section} />;
      case "liveTime":
        return <LiveTime key={index} section={section} />;

      default:
        return <div key={index} />;
    }
  };

  return (
    <>
      <Layout
        settings={settings}
        top_banner={page?.top_banner}
        darkTheme={page?.theme === "dark"}
        className={page?.theme === "dark" ? "darkTheme" : ""}
        location={location}
        pageSlug={page?.slug?.current}
        pageTitle={page?.title || ""}
        social={page?.socialSharing}
        seo={page?.seo}
        header_options={page?.header_options}
      >
        {page?.content?.length ? (
          <div>
            {page?.content.map((section, index) =>
              renderSections(section, index)
            )}
          </div>
        ) : (
          <div></div>
        )}
      </Layout>
    </>
  );
};
const serialize = (classes, isCustom, isExerpt) => {
  const serializers = {
    types: {
      image: (props) => {
        const { width, height } = getImageSize(props.node.image.asset._ref);
        const multiplier = props.node.is_retina ? 0.5 : 1;
        let attrs = {
          width: width * multiplier,
          height: height * multiplier,
          src: generateImageUrl(props.node.image.asset._ref),
          loading: "lazy",
          className: props.node.cssClasses,
        };
        return <img {...attrs} alt={props.node.alt} />;
      },

      customImage: (props) => {
        const { width, height } = getImageSize(props.node.image.asset._ref);
        const multiplier = props.node.is_retina ? 0.5 : 1;
        let attrs = {
          width: width * multiplier,
          height: height * multiplier,
          src: generateImageUrl(props.node.image.asset._ref),
          loading: "lazy",
          className: props.node.cssClasses,
        };
        return <img {...attrs} alt={props.node.alt} />;
      },
      block: (props) => {
        switch (props.node.style) {
          case "normal":
            return (
              <p
                className={`${
                  !isCustom
                    ? "text-base lg:text-2xl leading-21 lg:leading-32"
                    : ""
                } ${classes}`}
              >
                {isExerpt
                  ? props?.children[0].substring(0, 150).concat("...")
                  : props.children}
              </p>
            );
          case "bullet":
            return <li className="list-item">{props.children}</li>;
          default:
            return (
              <p
                className={`${
                  !isCustom
                    ? "text-base lg:text-2xl leading-21 lg:leading-32"
                    : ""
                } ${classes}`}
              >
                {isExerpt
                  ? props?.children[0].substring(0, 150).concat("...")
                  : props.children}
              </p>
            );
        }
      },
    },
  };
  return serializers;
};

const serializeContentEditor = (classes, isCustom, isExerpt) => {
  const serializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "normal":
            return (
              <p
                className={`${
                  !isCustom
                    ? "text-xl lg:text-2xl lg:leading-32 leading:24"
                    : ""
                } ${classes}`}
              >
                {isExerpt
                  ? props?.children[0].substring(0, 150).concat("...")
                  : props.children}
              </p>
            );
          case "bigHeading":
            return (
              <h3 className="headline pb-4 font-bold text-4xl lg:text-6xl lg:leading-42">
                {props.children}
              </h3>
            );
          case "headline":
            return (
              <h3 className="headline pb-16 font-bold text-3xl lg:text-4xl lg:leading-42">
                {props.children}
              </h3>
            );
          case "smaller":
            return <p className="text-xl">{props.children}</p>;
          default:
            return <p className="text-2xl leading-32">{props.children}</p>;
        }
      },
      contentEditor: () => {},
      contentEditorMinimal: (props) => {},
      customImage: (props) => {
        const { width, height } = getImageSize(props.node.image.asset._ref);
        const multiplier = props.node.is_retina ? 0.5 : 1;
        let attrs = {
          width: width * multiplier,
          height: height * multiplier,
          src: generateImageUrl(props.node.image.asset._ref),
          loading: "lazy",
          className: props.node.cssClasses,
        };
        return <img {...attrs} alt={props.node.alt} />;
      },
      buttonLink: (props) => {
        const { url, text, new_window } = props.node;
        let classes =
          "bg-primary-red hover:bg-black text-white transition duration-300 ease-in-out px-7 py-4 rounded-full cursor-pointer";
        if (new_window) {
          return (
            <div className="mt-4">
              <a
                className={classes}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </a>
            </div>
          );
        } else {
          return (
            <div className="mt-4">
              <a className={classes} href={url}>
                {text}
              </a>
            </div>
          );
        }
      },
      typeformButton: (props) => {
        const { text, id } = props.node;
        return (
          <button
            onClick={() => typeFormClickHandler(id)}
            className={`${classes}`}
          >
            {text}
          </button>
        );
      },
      contentEditorSpacer: (props) => {
        const { height } = props.node;
        return (
          <span
            style={{ display: "block", height: height + "px" }}
            aria-hidden="true"
          />
        );
      },
    },
    marks: {
      highlight: (props) => {
        return (
          <span className="highlight text-3xl lg:text-4xl lg:leading-42 text-white lg:text-dark-beige font-bold font-Dazzed">
            {props.children}
          </span>
        );
      },
    },
    list: (props) => {
      return <ul>{props?.children}</ul>;
    },
    listItem: (props) => {
      return (
        <li className="content-list relative text-2xl leading-32 mb-3">
          <svg
            className="icon-arrow-right absolute"
            style={{
              height: "8px",
              left: "-30px",
              width: "28px",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="#B4A993"
            viewBox="0 0 28 8"
            aria-hidden="true"
          >
            <path d="M27.3536 4.35355C27.5488 4.15829 27.5488 3.84171 27.3536 3.64644L24.1716 0.464464C23.9763 0.269202 23.6597 0.269202 23.4645 0.464464C23.2692 0.659726 23.2692 0.976309 23.4645 1.17157L26.2929 4L23.4645 6.82843C23.2692 7.02369 23.2692 7.34027 23.4645 7.53553C23.6597 7.73079 23.9763 7.73079 24.1716 7.53553L27.3536 4.35355ZM4.37114e-08 4.5L27 4.5L27 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"></path>
          </svg>
          {props.children}
        </li>
      );
    },
  };
  return serializers;
};
const typeFormClickHandler = (typeformId) => {
  const popup = typeformEmbed.makePopup(
    `https://form.typeform.com/to/${typeformId}`,
    {
      mode: "popup",
      hideHeaders: true,
      hideFooters: true,
      autoOpen: false,
    }
  );
  popup.open();
};
export default IndexPage;
