import React from "react";
import { generateImageUrl } from "../../../sanity";

const CourseDetailpricing = ({ section }) => {
  return (
    <div>
      <div className="container mx-auto mt-6">
        <div className="bg-medium-beige w-11/12 rounded px-10 py-10 mx-auto lg:w-70">
          <h1 className="text-3xl font-semibold text-center mb-10">
            {section.title}
          </h1>
          <div className="flex flex-wrap">
            {section?.section.map((single, index) => (
              <div key={index} className="w-full lg:w-30 mx-auto mb-12 lg:mb-0">
                <div className="flex items-center lg:mb-10">
                  <div className="w-10">
                    {" "}
                    {single?.headingImage && (
                      <img
                        src={generateImageUrl(
                          single?.headingImage?.asset?._ref
                        ).url()}
                        loading="lazy"
                        alt="Courseimg"
                      />
                    )}{" "}
                  </div>
                  <h1 className="text-2xl font-semibold ml-2 ">
                    {" "}
                    {single.heading}
                  </h1>
                </div>

                <ul className="list-disc text-base ml-6">
                  {single?.list?.map((listItem, listItemIndex) => {
                    return (
                      <li className="mt-4 text-lg" key={listItemIndex}>
                        {listItem.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseDetailpricing;
